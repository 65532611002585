import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { StaticImage } from "gatsby-plugin-image";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import MetaTag from "../components/metaTag";
import { aboutSEO } from "../components/data/seo";
import {
  container,
  preview,
  title,
  titledesc,
  slide,
  photo,
  info,
  text,
  works,
  vector,
  aboutswiper,
  video,
  background,
} from "../styles/pages/about.module.scss";
import "swiper/css";
import "swiper/css/pagination";

function About() {
  return (
    <>
      <MetaTag data={aboutSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
        className={container}
      >
        <div className={preview}>
          <h2 className={title}>О нас</h2>
          <p className={titledesc}>О Команде Cleanteam</p>
          <p>
            Как мы работаем?
            <br />С кем мы сотрудничаем?
          </p>
          <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
        </div>
        <div className={photo}>
          <Swiper
            pagination={true}
            modules={[Pagination]}
            className={aboutswiper}
          >
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/1.jpg"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/2.jpg"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/3.jpg"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/4.JPG"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/5.jpeg"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
            <SwiperSlide className={slide}>
              <StaticImage
                src="../images/about/6.jpeg"
                alt="Наша работа"
                className={background}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={info}>
          <p className={text}>
            Химчистка и уборка – основная сфера деятельности{" "}
            <span>Cleanteam</span>. В 2015 году наша клининговая компания начала
            свою работу в Красноярске. За годы мы обрели уникальный опыт работы,
            собрали команду профессиональных клинеров и завоевали доверие и
            репутацию клиентов.
          </p>
          <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
        </div>
        <div className={works}>
          <h2 className={title}>КАК МЫ РАБОТАЕМ?</h2>
          <p>
            <span>Заявка</span> (Оставьте заявку на сайте и получите 10% на
            первый заказ)
            <br />
            <br />
            <span>Расчет</span> (Наш менеджер свяжется с вами и уточнит детали
            предстоящей уборки)
            <br />
            <br />
            <span>Уборка</span> (В удобное для вас время специалисты приедут к
            Вам и произведут уборку
            <br />
            <br />
            <span>Оплата</span> (Оплата производится после того, как вы
            убедитесь, что все выполнено согласно вашим пожеланиям)
            <br />
            <br />
          </p>
        </div>
        <div className={video}>
          <video autoPlay muted loop playsInline>
            <source src="/video/about.mp4" type="video/mp4" />
          </video>
        </div>
      </motion.section>
    </>
  );
}

export default About;
