// extracted by mini-css-extract-plugin
export var aboutswiper = "about-module--aboutswiper--NFYgB";
export var background = "about-module--background--B3ZyY";
export var container = "about-module--container--1oOxu";
export var info = "about-module--info--GizVR";
export var photo = "about-module--photo--3aDxn";
export var preview = "about-module--preview--dxy+b";
export var slide = "about-module--slide--E0kzA";
export var text = "about-module--text--X30Cn";
export var title = "about-module--title--yClTn";
export var titledesc = "about-module--titledesc--GKxPk";
export var vector = "about-module--vector--m8Juo";
export var video = "about-module--video--erLGI";
export var works = "about-module--works--jfWlw";